.react-autosuggest__container {
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  background: transparent;
  border: none !important;
  padding: 10px 10px 10px 48px;

  &:focus {
    border: none !important;
    box-shadow: none;
    outline: none;
  }
}

ul.react-autosuggest__suggestions-list {
  list-style-type: none;
  padding-left: 22px;
}

.main-search-bar-no-suggest #react-autowhatever-mobile-search-input {
  display: none;
}
